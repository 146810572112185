const Config = {
  API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
  PHOTO_ID_BASE_URL: 'https://d1yv9s3sc0vjyv.cloudfront.net/',
  AWS: {
    S3Region: 'us-west-2',
    PhotoIDUploadBucket: 'cgi-userphoto',
    VideoResponseUploadBucket: 'cgi-videoquestion-files',
    AudioUploadBucket:'cgi-audioquestion-files',
    VideoResponseTestUploadBucket: 'cgi-videoquestion-files/UserResponseTest',
    UploadIdentityPoolId: 'us-west-2:d38248e4-2284-4d4a-997f-97d064c6a3bd',
    PhotoIdRootUrl: 'https://d1yv9s3sc0vjyv.cloudfront.net/',
    VideoResponseUrl: 'https://cgi-videoquestion-files.s3-us-west-2.amazonaws.com/',
    VideoTestUrl: 'https://cgi-videoquestion-files.s3-us-west-2.amazonaws.com/UserResponseTest/',
    VideoPreviewPhase3: 'https://cgi-videoquestion-files.s3.us-west-2.amazonaws.com/phase3question/'
  },
  PREMIUM_FEE: 9.99,
  PHASE2_FEE: 50,
  PHASE3_FEE: 150,
}

export default Config
